import React from 'react'
import HeaderImage from '../assets/header.png'
import Raj from '../assets/raj.svg'
import styles from '../css/Header.module.css'

const Header = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${HeaderImage})`
      }}
      className={styles.container}
    >
      <div className={styles.menu}>
        <a href="">Apps</a>
        <a href="">Services</a>
      </div>
      <div className={styles.headercontent}>
        <div/>
        <h1>
        Let’s Innovate <br /> & <br />Create Great things <br /> for the Future
        </h1>
      </div>
      <div className={styles.image}><img src={Raj} alt='Raj-image'/></div>
    </div>
  )
}

export default Header
