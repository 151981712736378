import React from 'react'
import MobileDev from '../assets/mobile-development.svg'
import WebDev from '../assets/web-development.svg'
import DevOps from '../assets/devops.svg'
import ProjManagement from '../assets/scrum-board.svg'
import UIUX from '../assets/ui-ux.svg'
import styles from '../css/Services.module.css'

const Services = () => {
  return (
    <div>
      <div className={styles.main}>
        <h1 className={styles.title}>SERVICES</h1>
        <div className={styles.container}>
          <div className={styles.firstdiv}>
            <div className={styles.section}>
              <img
                width="230px"
                height="200px"
                src={MobileDev}
                alt="mobile-development"
              />
              <h2>Mobile Development</h2>
            </div>
            <div className={styles.section}>
              <img
                width="215px"
                height="200px"
                src={WebDev}
                alt="web-development"
              />
              <h2>Web Development</h2>
            </div>
            <div className={styles.section}>
              <img width="230px" height="200px" src={UIUX} alt="UI-UX" />
              <h2>UI/UX</h2>
            </div>
            <div className={styles.section}>
              <img width="180px" height="210px" src={DevOps} alt="DevOps" />
              <h2>DevOps</h2>
            </div>
            <div className={styles.section}>
              <img
                width="200px"
                height="230px"
                src={ProjManagement}
                alt="Project-Management"
              />
              <h2>Project Management</h2>
            </div>
          </div>
        </div>
      </div>
      <div style={{height: 'auto'}}>
        <h3 style={{fontWeight: 'normal'}}>Email Me: raj@rapterr.com</h3>
      </div>
    </div>
  )
}

export default Services
