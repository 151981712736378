import React from 'react'
import UrbanCrop from '../assets/app-1.png'
import Fixtup from '../assets/app-2.png'
import Advocanna from '../assets/app-3.png'
import Subchannel from '../assets/app-4.png'
import Sportsfest from '../assets/app-5.png'
import styles from '../css/Apps.module.css'

const Apps = () => {
  return (
    <div className="">
      <h1 className={styles.title}>APPS</h1>
      <div className={styles.container}>
        <div className={styles.first}>
          <img src={UrbanCrop} width="230px" height="200px" alt="urban-crop" />
          <img src={Fixtup} width="215px" height="200px" alt="fixtup" />
          <img src={Advocanna} width="230px" height="200px" alt="advocanna" />
          <img src={Subchannel} width="230px" height="200px" alt="subchannel" />
          <img src={Sportsfest} width="200px" height="230px" alt="sportsfest" />
        </div>
      </div>
    </div>
  )
}

export default Apps
