import React from 'react';
import '../App.css';
import Header from '../components/Header';
import AboutMe from '../components/AboutMe';
import Apps from '../components/Apps';
import Services from '../components/Services';

export default function Home() {
  return (
    <div className="App">
     <Header/>
      <AboutMe/>
      <Apps/>
      <Services/>
    </div>
  )
}
