import React from 'react'
import GithubIcon from '../assets/github.png'
import LinkedinIcon from '../assets/linkedin_icon.png'
import styles from '../css/AboutMe.module.css'

const AboutMe = () => {
  const handleLinkClick = icon => {
    if (icon === 'github') {
      window.open('https://github.com/rajubushanam')
    } else {
      window.open('https://linkedin.com/in/srirajuvanteddu')
    }
  }

  return (
    <div className={styles.container}>
      <h2>
        Namaste!! This is Raj based out of Dallas, Texas. I am a fanatic of
        Technology especially the innovative part of it. Any new Technology
        solution, you got it! I will help you build your software from Design
        till consumer ready with great innovation
      </h2>
      <div className={styles.links}>
          <img
            className={styles.github}
            src={GithubIcon}
            onClick={() => handleLinkClick('github')}
            width="50px"
            height="50px"
            alt="github"
          />
          <img
            className={styles.linkedin}
            src={LinkedinIcon}
            onClick={() => handleLinkClick('linkedin')}
            width="50px"
            height="50px"
            alt="linkedin"
          />
      </div>
    </div>
  )
}

export default AboutMe
