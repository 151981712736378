import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import TermsOfServicePdf from '../assets/Rapterr-Terms-Of-Service.pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function TermsOfService() {
  const [pageNumber, setPageNumber] = useState(1)
  const [numOfPages, setNumOfPages] = useState(null)

  return (
    <div>
      <nav style={{ display: 'flex', justifyContent: 'center' }}>
        {pageNumber > 1 && (
          <button
            style={{ margin: '0 20px' }}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            Prev
          </button>
        )}
        <p>
          Page {pageNumber} of {numOfPages}
        </p>
        {pageNumber < numOfPages && (
          <button
            style={{ margin: '0 20px' }}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Next
          </button>
        )}
      </nav>
      <Document
        file={TermsOfServicePdf}
        onLoadSuccess={props => {
          setNumOfPages(props.numPages)
        }}
      >
        <Page pageNumber={pageNumber} width={800} />
      </Document>
    </div>
  )
}
