import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './containers/Home'
import PrivacyPolicy from './containers/PrivacyPolicy'
import TermsOfService from './containers/TermsOfService'

const App = () => {
  return (
    <Router>
      <Switch>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms-of-service">
            <TermsOfService />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
    
  );
};

export default App;
